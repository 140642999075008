import React, { useState } from "react";
import './styles.css'
import { useNavigate } from "react-router-dom";
import { Settings } from "solar-icon-set/settingsfinetuning";
import { UserCircle } from "solar-icon-set/users";
import { PaperAdd } from "solar-icon-set/notes";
import { useDispatch } from 'react-redux';
import { setLogoutUser } from '../../redux/userSlice';
import { useUser } from '../../redux/useStore';
import { ReactComponent as LogoutIconSVG } from '../../assets/logout.svg'
import { ReactComponent as ChanakyaLogo } from '../../assets/chanakyaLogo.svg'
import { setOrganisations } from "../../redux/organisationSlice";
function Sidebar() {
  const dispatch = useDispatch();
  const user = useUser();




  const navigate = useNavigate()

  const pathname = window.location.pathname

  const [showDropdown, setShowDropdown] = useState(false);


  console.log(user)
  const handleLogout = () => {
    dispatch(setLogoutUser());
    dispatch(setOrganisations([]));
    navigate('/login'); // Assuming you have a login route
    setShowDropdown(false);
  };

  const tabs = [
    {
      id:0,
      name:'Home',
      icon: (color: string) => (<UserCircle size={20} color={color} />),
      link: '/',
      allowedRoles: ['admin', 'teacher', 'student']
    },
    {
      id: 1,
      name: 'Assessment',
      icon: (color: string) => (<PaperAdd size={20} color={color} />),
      link: '/assessments',
      allowedRoles: ['admin', 'teacher']
    },

    {
      id: 3,
      name: 'Settings',
      icon: (color: string) => (<Settings size={20} color={color} />),
      link: '/settings',
      allowedRoles: ['admin']
    }
  ]

  const isActiveTab = (link: string) => {
    return link.split('/')[1] === pathname.split('/')[1]
  }


  return (
    <div className="sidebar">
      <div className="sidebar__header">
        <ChanakyaLogo width={200} height={25} />
        {/* <SidebarCode size={20} color={'black'} /> */}
      </div>

      <div className="sidebar__content">
        {
          tabs.map(tab => (
            <div key={tab.id}
              className={`sidebar__content__tab ${isActiveTab(tab.link) ? 'active' : ''}`}
              onClick={() => navigate(tab.link)}>
              {tab.icon(isActiveTab(tab.link) ? 'white' : 'gray')}
              <span>{tab.name}</span>
            </div>
          ))
        }
      </div>

      <div className="sidebar__footer">
        <div 
          className="sidebar__content__tab"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <UserCircle size={20} />
          <span>{user?.firstName || 'User'}</span>
        </div>
        {showDropdown && (
          <div className="logout-dropdown">
            <div className="logout-option" onClick={handleLogout}>
              <LogoutIconSVG width={20} height={20} />
              <span>Logout</span>
            </div>
          </div>
        )}
      </div>

    </div>
  );
}

export default Sidebar;