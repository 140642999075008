/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import './styles.css';
import { AltArrowDown, AltArrowLeft, AltArrowRight } from "solar-icon-set/arrows";
import { Colors } from "../../../utils/constants/colors";
import { useAxios } from "../../../hooks/useAxios";
import { ApiCollection } from "../../../config/envConfig";
import { PaperText } from "solar-icon-set/notes";
import { Pulse2 } from "solar-icon-set/medicine";
import FeedbackSection from "../../../components/feedbackSection";
import PageHeader from "../../../components/header";
import { useNavigate } from "react-router-dom";
import { MagniferZoomIn } from "solar-icon-set/search";

export default function AssessmentGradingScreen() {

    const axios = useAxios()
    const navigate = useNavigate();

    const [examData, setExamData] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);


    const [selectedQuestion, setSelectedQuestion] = React.useState<number>(0);
    const [showQuestions, setShowQuestions] = React.useState<boolean>(false);

    const [showImageModal, setShowImageModal] = React.useState<boolean>(false);

    const [currentAnswerType, setCurrentAnswerType] = React.useState<string>('text');


    useEffect(() => {
        getQuestions();
    }, []);

    const getQuestions = async () => {
        setIsLoading(true);
        await axios.get(ApiCollection.exam.getQuestions)
            .then((response) => {
                setIsLoading(false);
                console.log(response.data.answers);
                setExamData(response.data.answers);
                setSelectedQuestion(0);
            })
            .catch((error) => {
                setIsLoading(false);
                // setExamData(response)
                console.log(error);
            })
    }

    const validateAndNext = () => {
        if (selectedQuestion < examData.length - 1) {
            setSelectedQuestion(selectedQuestion + 1);
        }
    }

    const prevQuestion = () => {
        if (selectedQuestion > 0) {
            setSelectedQuestion(selectedQuestion - 1);
        }
    }

    const nextQuestion = () => {
        if (selectedQuestion < examData.length - 1) {
            setSelectedQuestion(selectedQuestion + 1);
        }
    }

    const getMarksColor = (marks: number, totalMarks: number) => {

        let percentage = (marks / totalMarks) * 100;

        if (percentage >= 80) {
            return Colors.success;
        } else if (percentage >= 50) {
            return Colors.yellow;
        } else {
            return Colors.error;
        }
    }


    if (isLoading) {
        return <div className="loader">Loading...</div>
    }


    return (
        examData.length > 0 ?
            <div className="assessment__grading_screen">
                <div className="wrapper" style={{ width: '95%', padding: 25 }}>
                    <PageHeader
                        title="Assessment / Student / Grading"
                        right={
                            <div className='page-action-button' onClick={() => navigate(`/assessments/analysis?student=1&assessment=1`)}>
                                <span>View Analysis</span>
                            </div>
                        }
                    />


                    <div className="question_holder">

                        <span>
                            Q{examData[selectedQuestion].question_number}. {examData[selectedQuestion].question_text}
                        </span>

                        <div style={{ cursor: 'pointer' }} onClick={() => setShowQuestions(true)}>
                            <AltArrowDown size={25} iconStyle="Outline" />
                        </div>
                    </div>

                    <div className="remarks_holder" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 40 }}>

                        <div className="grading_box remarks">
                            <div className="grading_box__header">
                                <h3>Remarks</h3>

                                <FeedbackSection />
                            </div>

                            <p>{examData[selectedQuestion].feedback}</p>

                        </div>

                        <div className="grading_box marks">
                            <div className="grading_box__header">
                                <h3>Marks</h3>
                                {/* 
                                <FeedbackSection /> */}
                            </div>
                            <div className="marks_holder">
                                <div >
                                    <span style={{ color: getMarksColor(examData[selectedQuestion].marks_awarded, examData[selectedQuestion].total_marks), fontSize: 45, fontWeight: 500 }} >{examData[selectedQuestion].marks_awarded}</span>
                                    <span style={{ fontSize: 22, marginLeft: 5, color: 'gray' }}>/</span>
                                    <span style={{ fontSize: 22, marginLeft: 5, color: 'gray' }}>{examData[selectedQuestion].total_marks}</span>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="answer_holder" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 40 }}>

                        <div className="grading_box student_answer">
                            <div className="grading_box__header">
                                <h3>Student Answer</h3>

                                <FeedbackSection />
                            </div>
                            {
                                currentAnswerType === 'text' ?
                                    <p>{examData[selectedQuestion].answer_text}</p>
                                    :
                                    <div className="image_holder">
                                        <img src={examData[selectedQuestion].image_path} alt="student_answer" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                    </div>
                            }

                            <div className="type_switcher"
                                onClick={() => setCurrentAnswerType(currentAnswerType === 'text' ? 'image' : 'text')}
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    left: '0',
                                    padding: '8px',
                                    cursor: 'pointer',
                                    backgroundColor: 'white',
                                    borderRadius: '4px',
                                    zIndex: 100
                                }}
                            >
                                {
                                    currentAnswerType === 'text' ?
                                        <Pulse2 size={20} iconStyle="Outline" />
                                        :
                                        <PaperText size={20} iconStyle="Outline" />
                                }



                            </div>

                            <div className="type_switcher"
                                onClick={() => setShowImageModal(true)}
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    right: '0',
                                    padding: '8px',
                                    cursor: 'pointer',
                                    backgroundColor: 'white',
                                    borderRadius: '4px',
                                    zIndex: 100
                                }}
                            >
                                {
                                    currentAnswerType === 'image' &&
                                    <MagniferZoomIn size={20} iconStyle="Outline" />
                                }



                            </div>
                        </div>

                        <div className="grading_box model_answer">
                            <div className="grading_box__header">
                                <h3>Model Answer</h3>

                                <FeedbackSection />
                            </div>
                            <p>
                                {examData[selectedQuestion].model_answer}
                            </p>

                        </div>

                    </div>


                    <div className="grading_footer">
                        <div className="grading_controls">
                            {
                                selectedQuestion > 0 ?
                                    <div className="grading_controls__button" onClick={prevQuestion}>
                                        <AltArrowLeft size={25} iconStyle="Outline" color={'black'} />
                                    </div>
                                    :
                                    <div className="grading_controls__button" style={{ opacity: 0.5, cursor: 'not-allowed' }}>
                                        <AltArrowLeft size={25} iconStyle="Outline" color={'black'} />
                                    </div>
                            }

                            {
                                selectedQuestion < examData.length - 1 ?
                                    <div className="grading_controls__button" onClick={nextQuestion}>
                                        <AltArrowRight size={25} iconStyle="Outline" color={'black'} />
                                    </div>
                                    :
                                    <div className="grading_controls__button" style={{ opacity: 0.5, cursor: 'not-allowed' }}>
                                        <AltArrowRight size={25} iconStyle="Outline" color={'black'} />
                                    </div>
                            }


                        </div>

                        <div className="grading_stats">
                            Assessment Accuracy: 95%
                        </div>

                        <div className="grading_actions">
                            {
                                selectedQuestion < examData.length - 1 ?
                                    <div className="grading_validate_button" onClick={validateAndNext}>
                                        Validate & Next
                                    </div>
                                    :
                                    <div className="grading_validate_button" onClick={validateAndNext}>
                                        Validate & Submit
                                    </div>
                            }

                        </div>

                    </div>
                </div>

                {
                    showQuestions &&
                    <div className="backdrop">
                        <div className="modal_content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <h2 style={{ color: Colors.primary, marginBottom: 15 }}>Select Questions</h2>
                                <span style={{ cursor: 'pointer' }} onClick={() => setShowQuestions(false)}>Close</span>
                            </div>

                            <div className="question_container">
                                {
                                    examData.map((question: any, index: number) => {
                                        return (
                                            <span className={`question_item ${selectedQuestion === index ? 'selected' : ''}`}
                                                onClick={() => {
                                                    setSelectedQuestion(index)
                                                    setShowQuestions(false)
                                                }}>
                                                <span>Q{question.question_number}. {question.question_text}</span>
                                                {
                                                    selectedQuestion === index &&
                                                    <span style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: Colors.primary, display: 'inline-block', marginLeft: 10 }}></span>
                                                }

                                            </span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }

                {
                    showImageModal &&
                    <div className="backdrop">
                        <div className="modal_content image_modal">
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                {/* <h2 style={{ color: Colors.primary, marginBottom: 15 }}>Select Questions</h2> */}
                                <span style={{ cursor: 'pointer' }} onClick={() => setShowImageModal(false)}>Close</span>
                            </div>

                            <div className="modal_image_container">
                                <img src={examData[selectedQuestion].image_path} alt="student_answer" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                            </div>
                        </div>
                    </div>
                }




            </div>
            :
            <></>
    )
}