import React from "react";
import './styles.css'
import { FileText, FileCheck } from "solar-icon-set/files";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import SpinnerLoader from "../../../components/loader";
import PageHeader from "../../../components/header";
import { students } from "../../../utils/constants/students";
import { useOrganisations } from "../../../redux/useStore";
import { database } from "../../../utils/constants/db";

const CreateAssessmentScreen = () => {

    const [isUploading, setIsUploading] = React.useState(false);
    const [paperPreview, setPaperPreview] = React.useState<string>('');
    const [answerKeyPreview, setAnswerKeyPreview] = React.useState<string>('');
    const [assessmentName, setAssessmentName] = React.useState<string>('');

    const navigate = useNavigate();

    const orgs = useOrganisations()

    const handlePaperChange = (file: File) => {

        const url = URL.createObjectURL(file);
        setPaperPreview(url);
    };

    const handleAnswerKeyChange = (file: File) => {
        const url = URL.createObjectURL(file);
        setAnswerKeyPreview(url);
    };

    // Clean up URLs when component unmounts
    React.useEffect(() => {
        return () => {
            if (paperPreview) URL.revokeObjectURL(paperPreview);
            if (answerKeyPreview) URL.revokeObjectURL(answerKeyPreview);
        };
    }, [paperPreview, answerKeyPreview]);

    const onSubmission = () => {

        if (!paperPreview || !answerKeyPreview) {
            toast.error("Please upload both the question paper and answer key to proceed")
            return;
        }

        if (!assessmentName) {
            toast.error("Please enter the assessment name to proceed")
            return;
        }



        console.log('submitting');
        setIsUploading(true);
        setTimeout(() => {
            setIsUploading(false);
            setAnswerKeyPreview('');
            setPaperPreview('');

            const ref = localStorage.getItem('assessments');
            const oldAssessments = ref ? JSON.parse(ref) : [];
            const assessmentId = `f${oldAssessments.length}`
            let newAssessment = {
                id: assessmentId,
                name: assessmentName,
                studentsEnrolled: 10,
                createdOn: new Date().toISOString(),
                status: "Not Submitted",
                submitted: 118,
            } as any;

            if (orgs.length > 0 && orgs[0].organisation_id === database.users.talakar.id) {
                newAssessment.organisationId = orgs[0].organisation_id
            }

            let storedStudents = localStorage.getItem('students') ? JSON.parse(localStorage.getItem('students')!) : students;

            let allStudents = storedStudents.filter((s: any) => s.assessmentId === 'functional')

            let newStudents: any[] = []

            allStudents.forEach((student: any) => {
                newStudents.push({
                    ...student,
                    status: 'Pending Upload',
                    assessmentId: newAssessment.id
                })
            })



            storedStudents = [...storedStudents, ...newStudents];
            localStorage.setItem('students', JSON.stringify(storedStudents));

            newAssessment.studentsEnrolled = newStudents.length;
            oldAssessments.unshift(newAssessment);
            localStorage.setItem('assessments', JSON.stringify(oldAssessments));

            toast.success("Assessment created successfully")
            navigate(-1)
        }, 2000);
    }



    return (
        <div className="assessment_create_screen">

            <div style={{ width: '95%', padding: 25 }}>
                <PageHeader title="Assessment / Create" />
                {
                    !isUploading ?
                        < div className="assessment_create__content">
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, justifyContent: 'center', alignItems: 'center' }}>
                                <h2 style={{ marginBottom: 0 }}>Upload Documents</h2>
                                <span style={{
                                    fontSize: 16, color: 'gray', margin: 0,
                                    borderBottom: '1px solid lightgray', padding: 10
                                }}>Upload the question paper and answer key for the assessment</span>

                            </div>

                            <div className="assessment_name">
                                <span>Assessment Name</span>
                                <input type="text" placeholder="Enter the name of the assessment"
                                    onChange={(e) => setAssessmentName(e.target.value)}
                                />
                            </div>
                            <div className="assessment_create__content__form">
                                <div className="file-upload-container">
                                    <input type="file" id="file-upload" accept=".pdf" className="file-input"
                                        onChange={(e) => {
                                            const files = e.target.files;
                                            if (files && files.length > 0) {
                                                handlePaperChange(files[0]);
                                            }
                                        }}
                                    />
                                    <label htmlFor="file-upload" className="file-label">
                                        {paperPreview ?
                                            <iframe
                                                src={paperPreview}
                                                width="80%"
                                                height="200px"
                                                title="Question Paper"
                                            />
                                            :
                                            <FileText size={40} className="file-icon" />}
                                        {
                                            paperPreview ?
                                                <span>Question Paper</span>
                                                :
                                                <span>Upload Question Paper</span>
                                        }
                                    </label>
                                </div>

                                <div className="file-upload-container">
                                    <input type="file" id="file-ans-upload" accept=".pdf" className="file-input"
                                        onChange={(e) => {
                                            const files = e.target.files;
                                            if (files && files.length > 0) {
                                                handleAnswerKeyChange(files[0]);
                                            }
                                        }}
                                    />
                                    <label htmlFor="file-ans-upload" className="file-label">
                                        {
                                            answerKeyPreview ?
                                                <iframe
                                                    src={answerKeyPreview}
                                                    width="80%"
                                                    height="200px"
                                                    title="Answer Key"
                                                />
                                                :


                                                <FileCheck size={40} className="file-icon" />}
                                        {
                                            answerKeyPreview ?
                                                <span>Answer key</span>
                                                :
                                                <span>Upload Answer Key</span>
                                        }
                                    </label>
                                </div>
                            </div>



                            <div className="submit_button" onClick={onSubmission}>
                                <span style={{ fontSize: 16 }}>Submit</span>
                            </div>
                        </div>

                        :
                        <div className="assessment_create__content" style={{ height: 650 }}>
                            <SpinnerLoader />
                            <h2>Uploading Assessment...</h2>


                        </div>

                }

            </div>
        </div >
    )
}


export default CreateAssessmentScreen;