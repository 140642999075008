export const studentData = {
  // Main title/description of the student report
  message: "Enhanced and Very Useful Student Report",
  data: {
    examOverview: {
      // Array containing detailed analysis of each answered question
      checked_answers: [
        {
          question_number: "Q.1 (A)",
          sub_questions: [
            {
              sub_question_number: "Q.1 (A)(1)",
              student_answer: "(c)",
              marks_awarded: 1,
              deducted_marks: 0,
              reason: "Correct identification of anaphase.",
            },
            {
              sub_question_number: "Q.1 (A)(2)",
              student_answer: "(a)",
              marks_awarded: 0,
              deducted_marks: 1,
              reason:
                "Student chose (a), but unisexual flowers have either androecium or gynoecium, not both.",
            },
            {
              sub_question_number: "Q.1 (A)(3)",
              student_answer: "(d)",
              marks_awarded: 1,
              deducted_marks: 0,
              reason: "Correctly identified humans as omnivorous.",
            },
            {
              sub_question_number: "Q.1 (A)(4)",
              student_answer: "(d)",
              marks_awarded: 1,
              deducted_marks: 0,
              reason:
                "Correctly stated that pigeon’s heart has four compartments.",
            },
            {
              sub_question_number: "Q.1 (A)(5)",
              student_answer: "d",
              marks_awarded: 0,
              deducted_marks: 1,
              reason:
                "Wrote 'All of the above' instead of the specific bacterium (Nostoc).",
            },
          ],
          total_marks_awarded: 3,
          total_marks_possible: 5,
        },
        {
          question_number: "Q.1 (B)",
          parts: [
            {
              question_number: "Q.1 (B)(1)",
              student_answer: "Penis",
              marks_awarded: 0.5,
              deducted_marks: 0.5,
              reason:
                "Mentioned only 'penis'; did not list other unpaired organs (e.g., prostate gland).",
            },
            {
              question_number: "Q.1 (B)(2)",
              student_answer: "Scatter curved grooves",
              marks_awarded: 0,
              deducted_marks: 1,
              reason:
                "The name given does not match the expected conserved forest name.",
            },
            {
              question_number: "Q.1 (B)(3)",
              student_answer: "inverter",
              marks_awarded: 1,
              deducted_marks: 0,
              reason:
                "Correctly identified the device that converts DC solar power to AC.",
            },
            {
              question_number: "Q.1 (B)(4)",
              student_answer: "",
              marks_awarded: 0,
              deducted_marks: 1,
              reason: "No answer was provided.",
            },
            {
              question_number: "Q.1 (B)(5)",
              student_answer: "Mental stress",
              marks_awarded: 1,
              deducted_marks: 0,
              reason:
                "Correctly noted that laughter clubs drive away mental stress.",
            },
          ],
          total_marks_awarded: 2.5,
          total_marks_possible: 5,
        },
        {
          question_number: "Q.2 (A)",
          sub_questions: [
            {
              sub_question_number: "Q.2 (A)(1)",
              student_answer: "(i) Embryological evidences ...",
              marks_awarded: 1.5,
              deducted_marks: 0.5,
              reason:
                "Discussed similarities in early stages but did not clarify that these similarities gradually decrease.",
            },
            {
              sub_question_number: "Q.2 (A)(2)",
              student_answer: "(ii) Disaster management ...",
              marks_awarded: 0,
              deducted_marks: 2,
              reason:
                "The response focuses on disaster management rather than saving energy.",
            },
          ],
          total_marks_awarded: 1.5,
          total_marks_possible: 4,
        },
        {
          question_number: "Q.2 (B)",
          parts: [
            {
              question_number: "Q.2 (B)(1)",
              student_answer: "(i) Regeneration means ...",
              marks_awarded: 1,
              deducted_marks: 1,
              reason:
                "Recognized tail regrowth but incorrectly labeled it unisexual and gave human skin as a confusing example.",
            },
            {
              question_number: "Q.2 (B)(2)",
              student_answer: "(iii) 1) Methanol 2) Ethanol 3) Natural gas",
              marks_awarded: 0.5,
              deducted_marks: 1.5,
              reason:
                "Mentioned ethanol, but methanol and natural gas are not typical biomass fuels.",
            },
            {
              question_number: "Q.2 (B)(3)",
              student_answer: "(v) Having a good personality ...",
              marks_awarded: 1.5,
              deducted_marks: 0.5,
              reason:
                "Explained social connectivity and some factors for good social health; omitted certain aspects like acceptance and respect.",
            },
          ],
          total_marks_awarded: 3,
          total_marks_possible: 6,
        },
        {
          question_number: "Q.3",
          parts: [
            {
              question_number: "Q.3(1)",
              student_answer: {
                "3.1 (i)": "Seven crore years ago",
                "3.1 (ii)": "Lemurs",
                "3.1 (iii)": "Two",
                "3.1 (iv)": "Ramapithecus",
                "3.1 (v)": "Neanderthal man",
                "3.1 (vi)": "Agriculture",
              },
              marks_awarded: 3,
              deducted_marks: 0,
              reason:
                "All fill-in-the-blanks correctly matched the required words.",
            },
            {
              question_number: "Q.3(2)",
              student_answer: {
                diagram: "Punnett square ...",
                written_explanation: [
                  "Male gametes = (22+X) or (22+Y)",
                  "Female gametes = (22+X)",
                  "Male chromosome decides child’s gender",
                  "Hence statement is true",
                ],
              },
              marks_awarded: 3,
              deducted_marks: 0,
              reason:
                "Clear depiction of sex determination with both diagram and explanation.",
            },
            {
              question_number: "Q.3(6)",
              student_answer: {
                written_explanation: [
                  "1. Biotechnology is used ...",
                  "2. Crops grow faster ...",
                  "3. BT cotton and BT brinjal ...",
                  "4. Genes of crops modified ...",
                ],
              },
              marks_awarded: 3,
              deducted_marks: 0,
              reason:
                "Mentioned biofertilizers, GM crops, and how biotechnology benefits agriculture.",
            },
          ],
          total_marks_awarded: 9,
          total_marks_possible: 15,
          note: "Student attempted only three out of the required five sub-questions.",
        },
        {
          question_number: "Q.4",
          choice_attempted: "Q.4 (2)",
          parts: [
            {
              question_number: "4.2",
              student_answer: {
                diagram_description: "Accurately labeled ecosystem chart ...",
                answers: {
                  Abiotic_components: [
                    "Physical factors: Air, water vapor, minerals, soil, sunlight",
                    "Inorganic substances: Hydrogen, Oxygen, Nitrogen, Calcium, Iron, Sodium, Potassium",
                  ],
                  Biotic_components: [
                    "Autotrophic: Producers (Plants)",
                    "Heterotrophic: Consumers (Animals), Decomposers (Fungi)",
                    "Consumers: Primary consumers, Secondary consumers, Tertiary consumers",
                  ],
                },
              },
              marks_awarded: 5,
              deducted_marks: 0,
              reason:
                "Completely and accurately filled the ecosystem classification boxes.",
            },
          ],
          total_marks_awarded: 5,
          total_marks_possible: 5,
        },
      ],

      // Overall exam statistics and performance summary
      examOverview: {
        totalMarksScored: 24, // Total marks obtained by student
        maximumMarks: 40, // Maximum possible marks in exam
        totalQuestions: 25, // Total number of questions in exam
        questionsAttempted: 23, // Number of questions student attempted
        exam_summary:
          "Great effort! You did really well in Heredity & Evolution and Life Processes. Some answers in Reproduction and Environment were either incomplete or had small mistakes (like forgetting 'Nostoc' or mixing up flower types). With careful reading and more practice, you can do even better!",
      },
      questions_compulsory: 25, // Total number of mandatory questions
      questions_attempted: 23, // Total questions answered by student
    },

    // Topic-wise performance analysis with SWOT categorization
    topicPerformance: [
      {
        topicName: "Life Processes", // Name of the subject topic
        marksScored: 3, // Marks obtained in this topic
        totalMarksAvailable: 3, // Maximum possible marks for topic
        performanceTag: "STRENGTH", // SWOT classification (STRENGTH/WEAKNESS/OPPORTUNITY/THREAT)
        questionsAttemptedPercentage: 100, // Percentage of questions attempted in topic
      },
      {
        topicName: "How Do Organisms Reproduce?",
        marksScored: 1.5,
        totalMarksAvailable: 4,
        performanceTag: "WEAKNESS",
        questionsAttemptedPercentage: 75,
      },
      {
        topicName: "Heredity and Evolution",
        marksScored: 8.5,
        totalMarksAvailable: 9,
        performanceTag: "STRENGTH",
        questionsAttemptedPercentage: 95,
      },
      {
        topicName: "Our Environment",
        marksScored: 8.5,
        totalMarksAvailable: 15,
        performanceTag: "OPPORTUNITY",
        questionsAttemptedPercentage: 60,
      },
      {
        topicName: "Other Topics (e.g., Physics)",
        marksScored: 4,
        totalMarksAvailable: 5,
        performanceTag: "NEEDS ATTENTION",
        questionsAttemptedPercentage: 80,
      },
    ],

    // Detailed question-by-question analysis for each topic
    detailedPerformance: [
      {
        topicName: "Life Processes",
        questions: [
          {
            questionNumber: "Q.1(A)(3)", // Question identifier
            questionDescription: "Omnivorous diets", // Brief description of question
            marksScored: 1, // Marks obtained
            maximumMarks: 1, // Maximum possible marks
          },
          {
            questionNumber: "Q.1(A)(4)",
            questionDescription: "Pigeon's four-chambered heart",
            marksScored: 1,
            maximumMarks: 1,
          },
          {
            questionNumber: "Q.1(B)(5)",
            questionDescription: "Mental stress alleviation",
            marksScored: 1,
            maximumMarks: 1,
          },
        ],
        chapterFeedback:
          "You excelled in basic life processes and health-related points. Keep up the great work!",
      },
      {
        topicName: "How Do Organisms Reproduce?",
        questions: [
          {
            questionNumber: "Q.1(A)(2)",
            questionDescription: "Unisexual vs. bisexual flowers",
            marksScored: 0,
            maximumMarks: 1,
          },
          {
            questionNumber: "Q.1(B)(1)",
            questionDescription: "Male reproductive organ identification",
            marksScored: 0.5,
            maximumMarks: 1,
          },
          {
            questionNumber: "Q.2(B)(1)",
            questionDescription: "Regeneration in organisms",
            marksScored: 1,
            maximumMarks: 2,
          },
        ],
        chapterFeedback:
          "Review reproductive structures and biological definitions carefully for improved accuracy.",
      },
      {
        topicName: "Heredity and Evolution",
        questions: [
          {
            questionNumber: "Q.1(A)(1)",
            questionDescription: "Correct mitosis phase identification",
            marksScored: 1,
            maximumMarks: 1,
          },
          {
            questionNumber: "Q.2(A)(1)",
            questionDescription: "Embryological evidences",
            marksScored: 1.5,
            maximumMarks: 2,
          },
          {
            questionNumber: "Q.3(1)",
            questionDescription: "Evolution timeline",
            marksScored: 3,
            maximumMarks: 3,
          },
          {
            questionNumber: "Q.3(2)",
            questionDescription: "Sex determination in humans",
            marksScored: 3,
            maximumMarks: 3,
          },
        ],
        chapterFeedback:
          "Outstanding understanding of evolution concepts and genetic mechanisms.",
      },
      {
        topicName: "Our Environment",
        questions: [
          {
            questionNumber: "Q.1(A)(5)",
            questionDescription: "Identification of Nostoc bacteria",
            marksScored: 0,
            maximumMarks: 1,
          },
          {
            questionNumber: "Q.1(B)(2)",
            questionDescription: "Sacred groves conservation",
            marksScored: 0,
            maximumMarks: 1,
          },
          {
            questionNumber: "Q.1(B)(4)",
            questionDescription: "Microbes in rubber production",
            marksScored: 0,
            maximumMarks: 1,
          },
          {
            questionNumber: "Q.2(A)(2)",
            questionDescription: "Energy conservation methods",
            marksScored: 0,
            maximumMarks: 2,
          },
          {
            questionNumber: "Q.2(B)(2)",
            questionDescription: "Biomass fuels examples",
            marksScored: 0.5,
            maximumMarks: 2,
          },
          {
            questionNumber: "Q.3(6)",
            questionDescription: "Biotechnology in agriculture",
            marksScored: 3,
            maximumMarks: 3,
          },
          {
            questionNumber: "Q.4(2)",
            questionDescription: "Ecosystem diagram classification",
            marksScored: 5,
            maximumMarks: 5,
          },
        ],
        chapterFeedback:
          "Focus on factual recall and examples. Great performance on large questions like diagrams and biotechnology!",
      },
      {
        topicName: "Other Topics (e.g., Physics)",
        questions: [
          {
            questionNumber: "Q.1(B)(3)",
            questionDescription: "Inverter function in solar power",
            marksScored: 1,
            maximumMarks: 1,
          },
          {
            questionNumber: "Q.3(3)",
            questionDescription: "Nuclear power plant processes",
            marksScored: 3,
            maximumMarks: 3,
          },
          {
            questionNumber: "Q.3(4)",
            questionDescription: "Pisces classification",
            marksScored: 0,
            maximumMarks: 1,
          },
        ],
        chapterFeedback:
          "Good understanding of physics concepts. Review biological classification for improved performance.",
      },
    ],

    // Analysis of student's strong and weak areas
    strengths_and_weaknesses: {
      strengths: [
        // Array of student's strong points
        "Heredity & Evolution: You demonstrated an excellent grasp on how traits pass from parents to offspring and can draw clear diagrams (like Punnett squares).",
        "Life Processes: Perfect answers on basic animal features (omnivorous diets, four‐chambered heart). This shows strong fundamental knowledge.",
      ],
      weaknesses: [
        // Array of areas needing improvement
        "Reproduction: You mixed up unisexual flowers (having either androecium or gynoecium) and missed some male reproductive organs.",
        "Environment: A few key terms (e.g. 'Nostoc', 'Sacred groves') were incorrect or missing. Also, some answers on energy saving measures were not complete.",
      ],
    },

    // Recommended study strategies for improvement
    studyRecommendations: {
      improve_factual_recall: [
        // Methods to improve fact retention
        "Use flashcards to strengthen key terms like 'unisexual,' 'Nostoc,' and microbes.",
        "Review examples regularly to solidify memory.",
      ],
      practice_complete_answers: [
        // Tips for better answer writing
        "When listing multiple examples, include as many relevant points as possible for full marks.",
        "Answer practice questions under timed conditions to improve speed and precision.",
      ],
      focus_on_visual_learning: [
        // Visual learning strategies
        "Practice diagrams like ecosystems, Punnett squares, and biological processes.",
        "Use mind maps for topics like 'Reproduction' and 'Environment' to create clear visual associations.",
      ],
      targeted_weaknesses: [
        // Specific areas to focus on
        "Review topics with lower marks (e.g., Environment, Reproduction).",
        "Focus on high-weight chapters like 'Environment' for maximum score improvement.",
      ],
    },

    // Enhanced visual aids and detailed SWOT analysis
    enhanced_visuals_swot_and_insights: {
      visual_aids: [
        // Suggested visualization methods
        {
          type: "Bar Chart",
          description:
            "Compare marks scored vs. total marks per chapter (Ch. 5, 7, 8, 13).",
        },
        {
          type: "Heatmap / Table",
          description:
            "Highlight strong, moderate, and weak questions with color coding.",
        },
        {
          type: "Pie Chart",
          description:
            "Shows topic weightage (e.g., Heredity ~23%, Environment ~37%, Life Processes ~8%, Reproduction ~10%).",
        },
      ],
      swot_analysis: {
        // Detailed SWOT breakdown
        strengths: [
          "Heredity (Ch. 8): High accuracy and conceptual understanding.",
          "Life Processes (Ch. 5): Perfect performance on basic biological processes.",
        ],
        weaknesses: [
          "Environment (Ch. 13): Factual recall questions and short-answer precision.",
          "Reproduction (Ch. 7): Definitions (unisexual vs. bisexual), regeneration examples.",
        ],
        opportunities: [
          "High-weight topics (Environment, Heredity) can yield significant score boosts.",
          "Diagrams & Flowcharts often secure full marks if well-labeled.",
        ],
        threats: [
          "Incomplete answers (losing marks for missing details).",
          "Carelessness (misreading short questions).",
        ],
      },
      study_strategy_and_tips: [
        // Comprehensive study plan
        "Create a chapter-wise study plan, focusing on Environment (Ch. 13) and Reproduction (Ch. 7).",
        "Visualize concepts: practice diagrams (ecosystem, reproductive systems).",
        "Use a timer for short-answer precision: fully list required points.",
        "Focus on factual recall: flashcards for microbes, reproductive organs, etc.",
        "Solve past papers to catch recurring errors and question patterns.",
      ],
      key_data_points: {
        // Important performance metrics
        total_marks_scored: 24,
        strongest_chapter: "Heredity (Ch. 8) at 8.5/9 (~94% accuracy)",
        weakest_chapter: "Environment (Ch. 13) at 8.5/15 (~57% accuracy)",
        primary_improvement_area:
          "Memorizing factual data in Environment & Reproduction",
        weightage_of_weak_topics: "~47% (Environment + Reproduction combined)",
      },
      final_summary: [
        // Concluding recommendations
        "Visualize and memorize with diagrams and mind maps.",
        "Focus on high-weight chapters (Environment & Heredity) for maximum score improvement.",
        "Regular, consistent revision helps retain factual knowledge and improves recall under exam conditions.",
      ],
    },
  },
};
