import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './navigation';
import { useEffect, useState } from 'react';
import { assessments } from './utils/constants/assessments';
import { students } from './utils/constants/students';
import SpinnerLoader from './components/loader';

function App() {

  const [isSeeding, setIsSeeding] = useState(true);

  useEffect(() => {
    seedData();
  }, []);

  const seedData = () => {
    setIsSeeding(true);
    localStorage.setItem('assessments', JSON.stringify(assessments));
    localStorage.setItem('students', JSON.stringify(students));
    setIsSeeding(false);
  }

  if(isSeeding) {
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:'column', height: '100vh'}}>
      <SpinnerLoader  />
      <h2>
       Loading...
      </h2>
    </div>
  }


  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );

}

export default App;
