import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Scale,
    CoreScaleOptions,
    Tick,
    ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './styles.css';
import { Colors } from '../../utils/constants/colors';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface ChartData {
    name: string;
    attempted: number;
    notAttempted: number;
}

interface ComparisonChartProps {
    data: ChartData[];
}

const ComparisonChart: React.FC<ComparisonChartProps> = ({ data }) => {
    const maxLimit = (): number => {
        const attempted = data.map(row => row.attempted);
        const notAttempted = data.map(row => row.notAttempted);
        const maxValue = Math.max(...attempted, ...notAttempted);
        return Math.round(maxValue) + 5;
    };

    const options: ChartOptions<'bar'> = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 16
                    }
                }
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.raw}`,
                }
            },
        },
        scales: {
            x: {
                min: 0,
                max: maxLimit(),
                title: {
                    display: true,
                    text: 'Number of Questions',
                    font: {
                        size: 16
                    }
                },
                grid: {
                    color: '#f0f0f0',
                },
                stacked: true,
                ticks: {
                    maxRotation: 0,
                    autoSkip: true,
                    font: {
                        size: 14
                    }
                }
            },
            y: {
                grid: {
                    display: false,
                },
                stacked: true,
                ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    font: {
                        size: 14
                    },
                    callback: function(this: Scale<CoreScaleOptions>, tickValue: string | number, index: number, ticks: Tick[]): string {
                        const label = this.getLabelForValue(Number(tickValue)) as string;
                        return label.length > 40 ? label.substr(0, 40) + '...' : label;
                    }
                },
                afterFit: (scale: any) => {
                    scale.width = 300;
                }
            },
        },
        elements: {
            bar: {
                borderRadius: {
                    topLeft: 6,
                    topRight: 6,
                    bottomLeft: 6,
                    bottomRight: 6
                }
            }
        }
    };

    const chartData = {
        labels: data.map(row => row.name),
        datasets: [
            {
                label: 'Attempted Questions',
                data: data.map(row => row.attempted),
                backgroundColor: Colors.success,
                borderRadius: 6,
            },
            {
                label: 'Unattempted Questions',
                data: data.map(row => row.notAttempted),
                backgroundColor: 'rgba(211, 211, 211, 0.7)',
                borderRadius: 6,
            },
        ],
    };

    return (
        <div className="breakdown-chart-holder">
            <Bar options={options} data={chartData} />
        </div>
    );
};

export default ComparisonChart;