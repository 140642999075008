import React from "react";
import { Dislike, Like } from "solar-icon-set/like";
import { ChatDots, PenNewRound } from "solar-icon-set/messagesconversation";
import { Refresh } from "solar-icon-set/arrows";
import './styles.css';

export default function FeedbackSection() {
    return (
        <div className="grading_box_controls">
            <Refresh size={18} iconStyle="Outline" />
            <Like size={18} iconStyle="Outline" />
            <Dislike size={18} iconStyle="Outline" />
            <ChatDots size={18} iconStyle="Outline" />
            <PenNewRound size={18} iconStyle="Outline" />
        </div>
    )
}