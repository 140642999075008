import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "solar-icon-set/arrows";
import { useOrganisations } from "../../redux/useStore";

interface PageHeaderProps {
    title: string;
    right?: React.ReactNode;
}

export default function PageHeader(props: PageHeaderProps) {

    const navigate = useNavigate()

    const organisations = useOrganisations();
    const orgLogo = organisations[0]?.org_logo || null;

    return (

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
            <div className="page__header">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
                        <ArrowLeft size={20} iconStyle="Outline" />
                    </div>
                    <h3 style={{ margin: 0, marginLeft: 10, marginBottom: 5 }}>{props.title}</h3>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                {props.right}
                {orgLogo !== null && (
                    <img
                        src={orgLogo}
                        alt="Organization Logo"
                        className="grading-header-logo"
                    />
                )}
            </div>
        </div>

    )
}