import React from "react";
import './styles.css';
import Sidebar from "../Sidebar/Sidebar";
import { Bounce, ToastContainer } from "react-toastify";


export default function DrawerPageLayout(props: any) {


    return (
        <div className="drawer-screen">
            <Sidebar />
            <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className="drawer-screen-content">
                {props.children && props.children}
            </div>
        </div>

    )
}