import React, { useEffect } from "react";
import './styles.css'
import { AddCircle } from "solar-icon-set/essentionalui";
import { useNavigate } from "react-router-dom";
import AssessmentListTable from "../../../components/assessmentTable";
import { useOrg } from "../../../hooks/organisation/useOrg";
import { useOrganisations } from "../../../redux/useStore";
import { database } from "../../../utils/constants/db";

const AssessmentListScreen = () => {


    const navigate = useNavigate()
    const { getOrganisationInfo } = useOrg();

    const organisations = useOrganisations();
    const orgLogo = organisations[0]?.org_logo || "";

    const [assessments, setAssessments] = React.useState([]);


    useEffect(() => {
        Promise.all([getOrganisationInfo(), getAssessments()])
            .catch(error => console.error("Error fetching data:", error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisations]);





    const getAssessments = () => {
        const assessments = JSON.parse(localStorage.getItem('assessments') || '[]');
        if (organisations.length > 0 && organisations[0].organisation_id === database.users.talakar.id) {
            const orgAssessments = assessments.filter((assessment: any) => assessment.organisationId === database.users.talakar.id);
            setAssessments(orgAssessments || []);
        } else {
            const dummyAssessments = assessments.filter((assessment: any) => assessment.organisationId === undefined);
            setAssessments(dummyAssessments || []);
        }
    }

    const onAssessmentClick = (assessment: any) => {
        navigate(`/assessments/students?assessmentId=${assessment.id}`)
    }


    return (
        <div className="assessment_list">

            <div style={{ width: '95%', padding: 25 }}>
                <div className="assessment_list__header">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2 style={{ marginLeft: 10 }}>Asssessments</h2>
                    </div>
                    {orgLogo && (
                        <img
                            src={orgLogo}
                            alt="Organization Logo"
                            className="assessment_list__header__logo"
                        />
                    )}
                </div>

                <div className="assessment_list__content">
                    {
                        assessments.length > 0 &&
                        <AssessmentListTable
                            onAssessmentClick={onAssessmentClick}
                            data={assessments} />
                    }

                </div>
            </div>

            <div className="assessment_list__header__actions__button" onClick={() => navigate('/assessments/create')}>
                <AddCircle size={30} />
                <span style={{ marginLeft: 10, fontSize: 16 }}>Create</span>
            </div>
        </div>
    )
}

export default AssessmentListScreen;