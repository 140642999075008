import { database } from "./db";

export const assessments = [
    {
        id: `d0`,
        name: "Science 2",
        studentsEnrolled: 5,
        createdOn: "2024-12-24",
        status: "Not Submitted",
        submitted: 118,
        organisationId: database.users.talakar.id
    },
    {
        id: `d1`,
        name: "Science Test",
        studentsEnrolled: 5,
        createdOn: "2024-12-24",
        status: "Submitted",
        submitted: 118,
        
    },
    {
        id: `d2`,
        name: "History Test",
        studentsEnrolled: 5,
        createdOn:"2024-12-22",
        status: "Not Submitted",
        submitted: 108
    },
    {
        id: `d3`,
        name: "Mathematics Test",
        studentsEnrolled: 5,
        createdOn: "2024-12-20",
        status: "Submitted",
        submitted: 122
    },
    {
        id: `d4`,
        name: "English Test",
        studentsEnrolled: 5,
        createdOn: "2024-12-22",
        status: "Submitted",
        submitted: 128
    },
    // {
    //     id: 5,
    //     name: "Physics Practical - Ray Optics",
    //     studentsEnrolled: 120,
    //     createdOn: "2024-03-01",
    //     status: "Submitted",
    //     submitted: 120
    // },
    // {
    //     id: 6,
    //     name: "Chemistry Periodic Table Quiz",
    //     studentsEnrolled: 115,
    //     createdOn: "2024-02-28",
    //     status: "Not Submitted",
    //     submitted: 110
    // },
    // {
    //     id: 7,
    //     name: "Mathematics Vectors MCQ",
    //     studentsEnrolled: 125,
    //     createdOn: "2024-02-25",
    //     status: "Submitted",
    //     submitted: 123
    // },
    // {
    //     id: 8,
    //     name: "English Grammar Assessment",
    //     studentsEnrolled: 130,
    //     createdOn: "2024-02-20",
    //     status: "Submitted",
    //     submitted: 129
    // },
    // {
    //     id: 9,
    //     name: "Physics Electrostatics Test",
    //     studentsEnrolled: 120,
    //     createdOn: "2024-02-15",
    //     status: "Not Submitted",
    //     submitted: 115
    // },
    // {
    //     id: 10,
    //     name: "Chemistry Practical - Titration",
    //     studentsEnrolled: 115,
    //     createdOn: "2024-02-10",
    //     status: "Submitted",
    //     submitted: 115
    // },
    // {
    //     id: 11,
    //     name: "Mathematics Probability Quiz",
    //     studentsEnrolled: 125,
    //     createdOn: "2024-02-05",
    //     status: "Not Submitted",
    //     submitted: 120
    // },
    // {
    //     id: 12,
    //     name: "English Speaking Assessment",
    //     studentsEnrolled: 130,
    //     createdOn: "2024-02-01",
    //     status: "Submitted",
    //     submitted: 128
    // },
    // {
    //     id: 13,
    //     name: "Physics Wave Optics Test",
    //     studentsEnrolled: 120,
    //     createdOn: "2024-01-28",
    //     status: "Submitted",
    //     submitted: 117
    // },
    // {
    //     id: 14,
    //     name: "Chemistry Coordination Compounds",
    //     studentsEnrolled: 115,
    //     createdOn: "2024-01-25",
    //     status: "Not Submitted",
    //     submitted: 110
    // },
    // {
    //     id: 15,
    //     name: "Mathematics 3D Geometry",
    //     studentsEnrolled: 125,
    //     createdOn: "2024-01-20",
    //     status: "Submitted",
    //     submitted: 123
    // },
    // {
    //     id: 16,
    //     name: "English Writing Skills",
    //     studentsEnrolled: 130,
    //     createdOn: "2024-01-15",
    //     status: "Submitted",
    //     submitted: 129
    // },
    // {
    //     id: 17,
    //     name: "Physics Current Electricity",
    //     studentsEnrolled: 120,
    //     createdOn: "2024-01-10",
    //     status: "Not Submitted",
    //     submitted: 116
    // },
    // {
    //     id: 18,
    //     name: "Chemistry Practical - Salt Analysis",
    //     studentsEnrolled: 115,
    //     createdOn: "2024-01-05",
    //     status: "Submitted",
    //     submitted: 115
    // },
    // {
    //     id: 19,
    //     name: "Mathematics Matrices Test",
    //     studentsEnrolled: 125,
    //     createdOn: "2024-01-02",
    //     status: "Submitted",
    //     submitted: 122
    // },
    // {
    //     id: 20,
    //     name: "English Comprehension Test",
    //     studentsEnrolled: 130,
    //     createdOn: "2024-01-01",
    //     status: "Not Submitted",
    //     submitted: 125
    // }
];