import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalUser } from "../utils/interface/localUser";
import { RootState } from "./store";

interface UserState {
  user: LocalUser | null;
  isLoggedIn: boolean;
}

const initialState: UserState = {
  user: null,
  isLoggedIn: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setActiveUser: (state, action: PayloadAction<LocalUser>) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    setLogoutUser: (state) => {
      state.user = null;
      state.isLoggedIn = false;
    },
  },
});

export const { setActiveUser, setLogoutUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;
export const selectIsLoggedIn = (state: RootState) => state.user.isLoggedIn;

export default userSlice.reducer;
