import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrganisation } from "../utils/interface/organisation";

interface OrgState {
  organisations: IOrganisation[];
  loading: boolean;
  error: string | null;
}

const initialState: OrgState = {
  organisations: [],
  loading: false,
  error: null,
};

const orgSlice = createSlice({
  name: "org",
  initialState,
  reducers: {
    setOrganisations: (state, action: PayloadAction<IOrganisation[]>) => {
      state.organisations = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    // clearOrgState: state => {
    //   state.organisations = [];
    //   state.loading = false;
    //   state.error = null;
    // },
  },
});

export const { setOrganisations, setLoading, setError } = orgSlice.actions;
export default orgSlice.reducer;

// Selectors
export interface RootState {
  org: OrgState;
}

export const selectOrganisations = (state: RootState) =>
  state.org.organisations;
export const selectOrgLoading = (state: RootState) => state.org.loading;
export const selectOrgError = (state: RootState) => state.org.error;
export const selectStudentsByOrgId = (state: RootState, orgId: string) => {
  const org = state.org.organisations.find(
    (org) => org.organisation_id === orgId
  );
  return org?.students || [];
};
