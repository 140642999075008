import React from "react";
import './styles.css'

const StrengthWeaknessTable = (props:any) => {


    const data = props.data
  
  
    return (
      <div className="table-container">
        <table className="sw-table">
          <thead>
            <tr>
              <th >Strengths</th>
              <th>Weaknesses</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row:any, index:any) => (
              <tr key={index}>
                <td >{row.strength}</td>
                <td>{row.weakness}</td>
              </tr>
            ))}
          </tbody>
        </table>
       
      </div>
    );
  };
  
  export default StrengthWeaknessTable;