import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { studentData } from '../../../utils/constants/studentAnalysis';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/header';
import PerformanceTable from '../../../components/performanceTable';
import ComparisonChart from '../../../components/comparisonChart';
import ReccommendationTable from '../../../components/reccomendationTable';
import StrengthWeaknessTable from '../../../components/strengthWeaknessTable';


interface GaugeChartProps {
  correct: number;
  incorrect: number;
  notAttempted: number;
  total: number;
}

const GaugeChart = ({ correct, incorrect, notAttempted, total }: GaugeChartProps) => {
  const data = [
    { name: 'Obtained', value: correct, color: '#4CAF50' },
    { name: 'Lost', value: incorrect, color: '#FF5252' },
    { name: 'Not Attempted', value: notAttempted, color: '#2196F3' },
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value }: any) => {
    const radius = outerRadius * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const lineEndRadius = outerRadius + 20;
    const lineEndX = cx + lineEndRadius * Math.cos(-midAngle * RADIAN);
    const lineEndY = cy + lineEndRadius * Math.sin(-midAngle * RADIAN);

    const textAnchor = x > cx ? 'start' : 'end';


    const labelWidth = 135;
    
    
    const rectX = textAnchor === 'start' ? x : x - labelWidth;
    
    //text x position  centered in container
    const textX = textAnchor === 'start' ? x + (labelWidth / 2) : x - (labelWidth / 2);

    return (
      <g>
        {/* Connecting line */}
        <path
          d={`M ${cx + (outerRadius + 5) * Math.cos(-midAngle * RADIAN)},${
            cy + (outerRadius + 5) * Math.sin(-midAngle * RADIAN)
          } L ${lineEndX},${lineEndY}`}
          stroke={data[index].color}
          strokeWidth={1.5}
          fill="none"
        />
        
        {/* Label background */}
        <rect
          x={rectX}
          y={y - 12}
          width={labelWidth}
          height={24}
          rx={12}
          fill="white"
          stroke={data[index].color}
          strokeWidth={1}
          opacity={0.9}
        />
        

        <text
          x={textX}
          y={y}
          textAnchor="middle"
          fill={data[index].color}
          fontSize="13"
          fontWeight="600"
          dominantBaseline="middle"
        >
          {`${name}: ${value}`}
        </text>
      </g>
    );
  };

  return (
    <div className="gauge-container">
      <div className="donut-chart">
        <PieChart width={400} height={300}>
          <Pie
            data={data}
            cx={200}
            cy={150}
            innerRadius={80}
            outerRadius={120}
            paddingAngle={3}
            dataKey="value"
            startAngle={90}
            endAngle={450}
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <text 
            x={200} 
            y={140} 
            textAnchor="middle" 
            dominantBaseline="central"
            className="donut-center-text"
          >
            {total}
          </text>
          <text 
            x={200} 
            y={165} 
            textAnchor="middle" 
            dominantBaseline="central"
            className="donut-subtext"
          >
            Total Marks
          </text>
        </PieChart>
      </div>
    </div>
  );
};



interface ExamOverview {
  total_marks_scored: number;
  maximum_marks: number;
  total_questions: number;
  questions_attempted: number;
  exam_summary: string;
}

const StudentOverview = () => {
  const examData = {
    total_marks_scored: studentData.data.examOverview.examOverview.totalMarksScored,
    maximum_marks: studentData.data.examOverview.examOverview.maximumMarks,
    total_questions: studentData.data.examOverview.examOverview.totalQuestions,
    questions_attempted: studentData.data.examOverview.examOverview.questionsAttempted,
    exam_summary: studentData.data.examOverview.examOverview.exam_summary
  } as ExamOverview;

  const topicPerformance = studentData.data.topicPerformance;




  const navigate = useNavigate()

  const getSummaryData = topicPerformance.map((topic) => ({
    topic: topic.topicName,
    achieved: Math.round(topic.marksScored),
    total: topic.totalMarksAvailable,
    swot: topic.performanceTag,
    attempt: Math.round(topic.questionsAttemptedPercentage)
  }));

  const getChartData = studentData.data.detailedPerformance.map((topic) => {
    const totalQuestions = topic.questions.length;
    const attemptedQuestions = topic.questions.filter(q => q.marksScored > 0).length;

    return {
      name: topic.topicName,
      attempted: attemptedQuestions,
      notAttempted: totalQuestions - attemptedQuestions,
      score: `${attemptedQuestions}/${totalQuestions}`
    };
  });


  // for corrrect and incorrect marks
  const gaugeData = {
    correct: examData.total_marks_scored,
    incorrect: Math.round((examData.questions_attempted * (examData.maximum_marks / examData.total_questions) - examData.total_marks_scored)),
    notAttempted: Math.round((examData.total_questions - examData.questions_attempted) * (examData.maximum_marks / examData.total_questions)),
    total: examData.maximum_marks
  };

  const formatKey = (key: string): string => {
    return key
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const getReccomendation = () => {
    const recommendations = studentData.data.studyRecommendations;
    return Object.entries(recommendations).map(([key, value]) => ({
      topic: formatKey(key),
      details: value.join(' ')
    }));
  };

  const getStrengthWeakness = () => {
    const { strengths, weaknesses } = studentData.data.strengths_and_weaknesses;
    return [{
      strength: strengths.join(' '),
      weakness: weaknesses.join(' ')
    }];
  };



  return (
    <div className="student-overview-container">
      <PageHeader
        title='Assessment / Students / Analysis'
        right={
          <div className='page-action-button' onClick={() => navigate(`/assessments/grading?student=1&assessment=1`)}>
            <span>View Grading</span>
          </div>
        }
      />

      <h2 className="section-title">Overview</h2>


      <div className="stats-grid">

        <div className="stats-card">
          <h3 className="stats-card__label">Marks</h3>
          <div className="stats-card__value-container">
            <span className="stats-card__value">{examData.total_marks_scored}</span>
            <span className="stats-card__total">/{examData.maximum_marks}</span>
          </div>
          {/* <div className="stats-card__trend">
            &nbsp; 12% increase from previous test
          </div> */}
        </div>

        <div className="stats-card">
          <h3 className="stats-card__label">Questions Attempted</h3>
          <div className="stats-card__value-container">
            <span className="stats-card__value">
              {examData.questions_attempted}
            </span>
            <span className="stats-card__total">
              /{examData.total_questions}
            </span>
          </div>
          {/* <div className="stats-card__trend">
            &nbsp; 12% increase from previous test
          </div> */}
        </div>

        <div className="stats-card summary">
          <h3 className="stats-card__label">Exam Summary</h3>

          <span className="stat-summary">
            {examData.exam_summary}
          </span>
        </div>
      </div>


      <div className="charts-grid">

        <div className="chart-card topic-summary">
          <div className="topic-summary">
            <h2 className="topic-summary__title">Topic Summary</h2>

            <PerformanceTable data={getSummaryData} />
          </div>
        </div>

        <div className="chart-card attempt-progress">
          <GaugeChart {...gaugeData} />
        </div>
      </div>

      <div className="topic-summary-container">
        <div className="topic-breakdown">
          <h2 className="topic-breakdown__title">Topic Breakdown</h2>
          <ComparisonChart data={getChartData} />
        </div>
      </div>

      <div className="topic-summary-container">
        <div className="topic-breakdown">
          <h2 className="topic-breakdown__title">Student Analysis</h2>
          <StrengthWeaknessTable data={getStrengthWeakness()} />
        </div>
      </div>

      <div className="topic-summary-container">
        <div className="topic-breakdown">
          <h2 className="topic-breakdown__title">Exam Recommendation</h2>
          <ReccommendationTable data={getReccomendation()} />
        </div>
      </div>





    </div>
  );
};

export default StudentOverview;
