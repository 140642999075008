import React from "react";
import { AltArrowRight } from "solar-icon-set/arrows";
import './styles.css';


interface Assessment {
    id: number;
    name: string;
    studentsEnrolled: number;
    createdOn: string;
    status: string;
    submitted: number;
}

interface AssessmentListTableProps {
    data: Assessment[];
    onAssessmentClick?: (assessment: Assessment) => void;
}

export default function AssessmentListTable(props: AssessmentListTableProps) {



        return (
        <table className="assessment_table">
            <thead>
                <tr>
                    <th>Assessment Name</th>
                    <th className="extras">Students Enrolled</th>
                    <th className="extras">Created On</th>
                    <th>Status</th>

                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((assessment) => (
                    <tr className="assessment_table_data" key={assessment.id} onClick={() => props.onAssessmentClick && props.onAssessmentClick(assessment)}>
                        <td>{assessment.name}</td>
                        <td className="extras">{assessment.studentsEnrolled}</td>
                        <td className="extras">{new Date(assessment.createdOn).toLocaleDateString()}</td>
                        <td>
                            <span className={`status_badge ${assessment.status === 'Submitted' ? 'status_active' : 'status_inactive'}`}>
                                {assessment.status}
                            </span>
                        </td>
                        <td >
                            <AltArrowRight size={20} iconStyle="Outline" />
                        </td>

                    </tr>
                ))}
            </tbody>
        </table>
    )
}