/* eslint-disable eqeqeq */
import React from "react";
import './styles.css'
import { useNavigate, useSearchParams } from "react-router-dom";
import PageHeader from "../../../components/header";
import { CloseCircle } from "solar-icon-set/essentionalui";
import SpinnerLoader from "../../../components/loader";
import { toast } from "react-toastify";


export default function UploadAnswerScreen() {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    const [sheets, setSheets] = React.useState<File[]>([]);
    const [isUploading, setIsUploading] = React.useState(false);

    const onSubmit = () => {

        if (sheets.length === 0) {
            alert('Please upload answer sheets');
            return;
        }

        console.warn('submit');
        setIsUploading(true);

        setTimeout(() => {
            setSheets([]);
            setIsUploading(false);
            const students = JSON.parse(localStorage.getItem('students') || '[]');
            const studentId = searchParams.get('student');
            const assessmentId = searchParams.get('assessment');
            let student = students.find((student: any) => (student.id == studentId && student.assessmentId == assessmentId));
            student.status = 'Submitted';
            localStorage.setItem('students', JSON.stringify(students));
            navigate(-1);
            toast.success("Answers submitted successfully");
        }, 1500);
    }

    return (
        <div className="upload_answer_screen">
            <div style={{ width: '95%', padding: 25 }}>
                <PageHeader
                    title="Assessment / Student / Submittion"
                    right={
                        (sheets.length > 0 && isUploading === false) &&
                        <div className='page-action-button' onClick={onSubmit}>
                            <span>Submit</span>
                        </div>
                    }

                />


                <h2 className="upload_answer_screen_title">Upload Answer Sheets</h2>
                {
                    !isUploading ?
                        <div className="upload_answer_screen_content">
                            {
                                sheets.length > 0 &&
                                sheets.map((sheet, index) => (
                                    <div className="answer_sheet_preview" key={index}>
                                        <img src={URL.createObjectURL(sheet)} alt="answer sheet" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                        {/* <span>Page {index+1}</span> */}
                                        <div className="answer_sheet_preview_overlay"
                                            onClick={() => {
                                                setSheets(sheets.filter((_, i) => i !== index));
                                            }} >
                                            <CloseCircle size={20} color={'white'} iconStyle="Bold" />
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="upload_answer_sheet_button">
                                <label className="upload-button">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        multiple
                                        onChange={(e) => {
                                            const files = e.target.files || [];
                                            if (files.length > 0) {
                                                console.warn(files);
                                                let temp = [...sheets];
                                                for (let i = 0; i < files.length; i++) {
                                                    temp.push(files[i]);
                                                }
                                                setSheets(temp);
                                            }
                                        }}
                                    />
                                    <div className="upload-content">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                            <polyline points="17 8 12 3 7 8" />
                                            <line x1="12" y1="3" x2="12" y2="15" />
                                        </svg>
                                        <span>Upload Image</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        :
                        <div className="upload_answer_screen_content" style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <SpinnerLoader />
                            <h2>Submitting Answers...</h2>
                        </div>
                }

            </div>
        </div>
    )
}