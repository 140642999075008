interface Student {
    id: number;
    name: string;
    roll: number;
    profileImage: string;
    status: 'Submitted' | 'Pending Upload';
    assessmentId: string;
}

export const students: Student[] = [
    {
        id: 1,
        name: "Aisha Patel",
        roll: 45,
        profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=1",
        status: 'Submitted',
        assessmentId: 'dummy'
    },

    {
        id: 3,
        name: "Priya Singh",
        roll: 67,
        profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=3",
        status: 'Submitted',
        assessmentId: 'dummy'
    },
    {
        id: 4,
        name: "Arjun Malhotra",
        roll: 89,
        profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=4",
        status: 'Pending Upload',
        assessmentId: 'dummy'
    },
    {
        id: 2,
        name: "Raj Kumar",
        roll: 23,
        profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=2",
        status: 'Pending Upload',
        assessmentId: 'dummy'
    },
    {
        id: 5,
        name: "Zara Mehta",
        roll: 34,
        profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=5",
        status: 'Submitted',
        assessmentId: 'dummy'
    },
    // {
    //     id: 6,
    //     name: "Vikram Reddy",
    //     roll: 78,
    //     profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=6",
    //     status: 'Pending Upload',
    //     assessmentId: 'dummy'
    // },
    // {
    //     id: 7,
    //     name: "Neha Sharma",
    //     roll: 12,
    //     profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=7",
    //     status: 'Submitted',
    //     assessmentId: 'dummy'
    // },
    // {
    //     id: 8,
    //     name: "Kabir Sinha",
    //     roll: 56,
    //     profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=8",
    //     status: 'Pending Upload',
    //     assessmentId: 'dummy'
    // },
    // {
    //     id: 9,
    //     name: "Maya Iyer",
    //     roll: 90,
    //     profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=9",
    //     status: 'Submitted',
    //     assessmentId: 'dummy'
    // },
    // {
    //     id: 10,
    //     name: "Rohan Kapoor",
    //     roll: 43,
    //     profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=10",
    //     status: 'Pending Upload',
    //     assessmentId: 'dummy'
    // },
    {
        id: 11,
        name: "Arjun Malhotra",
        roll: 34,
        profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=11",
        status: 'Pending Upload',
        assessmentId: 'functional'
    },
    {
        id: 12,
        name: "Priya Patel",
        roll: 67,
        profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=12",
        status: 'Pending Upload',
        assessmentId: 'functional'
    },
    {
        id: 13,
        name: "Aditya Kumar",
        roll: 89,
        profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=13",
        status: 'Pending Upload',
        assessmentId: 'functional'
    },
    {
        id: 14,
        name: "Zara Sheikh",
        roll: 23,
        profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=14",
        status: 'Pending Upload',
        assessmentId: 'functional'
    },
    // {
    //     id: 15,
    //     name: "Rahul Mehra",
    //     roll: 45,
    //     profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=15",
    //     status: 'Pending Upload',
    //     assessmentId: 'functional'
    // },
    // {
    //     id: 16,
    //     name: "Ananya Singh",
    //     roll: 78,
    //     profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=16",
    //     status: 'Pending Upload',
    //     assessmentId: 'functional'
    // },
    // {
    //     id: 17,
    //     name: "Karthik Rao",
    //     roll: 91,
    //     profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=17",
    //     status: 'Pending Upload',
    //     assessmentId: 'functional'
    // },
    // {
    //     id: 18,
    //     name: "Diya Verma",
    //     roll: 12,
    //     profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=18",
    //     status: 'Pending Upload',
    //     assessmentId: 'functional'
    // },
    // {
    //     id: 19,
    //     name: "Nikhil Joshi",
    //     roll: 56,
    //     profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=19",
    //     status: 'Pending Upload',
    //     assessmentId: 'functional'
    // },
    // {
    //     id: 20,
    //     name: "Riya Gupta",
    //     roll: 88,
    //     profileImage: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=20",
    //     status: 'Pending Upload',
    //     assessmentId: 'functional'
    // }
];