import React from 'react';
import './login.css';
import asssmentLogo from "../../assets/assesmentLogo.png"
import logoSvg from "../../assets/chanakyaLogo.svg"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setActiveUser } from '../../redux/userSlice';
import { useDispatch } from 'react-redux';
import { ApiCollection } from '../../config/envConfig';
import { LocalUser } from '../../utils/interface/localUser';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';


const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errors, setErrors] = React.useState({ username: '', password: '' });
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    

    setErrors({ username: '', password: '' });


    const newErrors = {
      username: !username ? 'Email is required' : '',
      password: !password ? 'Password is required' : ''
    };

    setErrors(newErrors);

    // Only proceed if no errors
    if (!newErrors.username && !newErrors.password) {
      onLoginPress();
    }
  };

  const onLoginPress = async () => {
  
    if (!username || !password) {
        setErrors({
            username: !username ? 'Username is required' : '',
            password: !password ? 'Password is required' : ''
        });
        return;
    }

    try {
        setLoading(true);
        const { data: userData } = await axios.post(ApiCollection.user.login, {
            email: username,
            password,
        });

        if (!userData) {
            throw new Error("No data received from server");
        }

        const userInfo: LocalUser = {
            id: userData.id,
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.email,
            isActive: userData.is_active,
            mobileNo: userData.mobile_no,
            dateOfBirth: userData.date_of_birth,
            token: userData.token,
            modifiedAt: userData.modified_at,
            userPermissions: userData.user_permissions,
        };

        dispatch(setActiveUser(userInfo));
        
        navigate('/assessments');
        toast.success('Login successful!');

    } catch (error: any) {
        const errorMessage = error.response?.data?.message || "An error occurred during login";
        toast.error(errorMessage);
    } finally {
        setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="login-content">
        <div className="login-left">
        <div className="overview-image-container">
            <img 
              src={asssmentLogo} 
              alt="Student Overview" 
              className="overview-image"
            />
          </div>
          <div className="text-content-container">
            <h1>Welcome to Chanakya Assessment Platform</h1>
            <p>Access comprehensive student evaluations and analytics to make data-driven 
               decisions for better learning outcomes. Track progress, identify areas for 
               improvement, and enhance educational effectiveness.</p>
          </div>
        </div>
        
        <div className="login-right">
          <img 
            src={logoSvg} 
            alt="Logo" 
            className="login-logo"
          />
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email address"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  if (errors.username) setErrors({...errors, username: ''});
                }}
                className={errors.username ? 'error-input' : ''}
              />
              {errors.username && <span className="error-text">{errors.username}</span>}
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (errors.password) setErrors({...errors, password: ''});
                }}
                className={errors.password ? 'error-input' : ''}
              />
              {errors.password && <span className="error-text">{errors.password}</span>}
            </div>
            <button type="submit" disabled={loading}>
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
