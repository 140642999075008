export const envConfig = {
  isProd: false, // Changed to false
  apiHeadDev: "https://dashapi.aichanakya.in",
  apiHeadProd: "https://dashapi.aichanakya.in",
};

//////////////////// API HEAD  ////////////////////
export const ApiHead = envConfig.isProd
  ? envConfig.apiHeadProd
  : envConfig.apiHeadDev;

//////////////////// APIS ENDPOINTS  ////////////////////

export const ApiCollection = {
  user: {
    login: `${ApiHead}/assessment/auth/login/`,
    // signup: `${ApiHead}/user/signup/`,
    // forget: `${ApiHead}/user/password-reset/`,
    // getAuthUser: `${ApiHead}/auth/get-user/`,
  },
  exam: {
    getQuestions: `${ApiHead}/assessment/student/`,
  },
  auth: {},
  organisation: {
    getOrganisationDetail: `${ApiHead}/assessment/organisation/`,
  },
};
