import { Routes, Route, Navigate } from 'react-router-dom';
import DrawerPageLayout from '../components/drawerWrapper';
import { AssessmentGradingScreen, AssessmentListScreen, CreateAssessmentScreen } from '../pages/assessment';
import { StudentListScreen, StudentOverview, UploadAnswerScreen } from '../pages/student';
import Login from '../pages/login/login';
import { useIsLoggedIn } from '../redux/useStore';


const drawerWrapped = (component: any) => {
  return (
    <DrawerPageLayout>
      {component}
    </DrawerPageLayout>
  )
}


const AppRoutes = () => {

  const isLoggedIn = useIsLoggedIn()

  // Protected Routes
  if(!isLoggedIn){
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/" element={drawerWrapped(<AssessmentListScreen  />)} />
      <Route path='/assessments'>
        <Route index path='' element={drawerWrapped(<AssessmentListScreen />)} />
        <Route path='create' element={drawerWrapped(<CreateAssessmentScreen />)} />
        <Route path="students" element={drawerWrapped(<StudentListScreen />)} />
        <Route path="grading" element={drawerWrapped(<AssessmentGradingScreen />)} />
        <Route path="submittion" element={drawerWrapped(<UploadAnswerScreen />)} />
        <Route path="analysis" element={drawerWrapped(<StudentOverview />)} />

      </Route>

      <Route path='/settings' element={drawerWrapped(<></>)} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
