/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import StudentListTable from "../../../components/studentTable";
import PageHeader from "../../../components/header";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";


const StudentListScreen = () => {


    const navigate = useNavigate()

    const [searchParams] = useSearchParams()

    const [students, setStudents] = useState([]);


    useEffect(() => {
        getStudents();
    }, []);


    const getStudents = () => {
        const assessmentId = searchParams.get('assessmentId');

        let students = []
        if (assessmentId?.includes('d')) {
            students = JSON.parse(localStorage.getItem('students') || '[]');
            students = students.filter((student: any) => student.assessmentId === 'dummy')
            setStudents(students);
        } else {
            students = JSON.parse(localStorage.getItem('students') || '[]');
            students = students.filter((student: any) => student.assessmentId === assessmentId)
            setStudents(students);
        }


    }

    const onStudentClick = (student: any) => {

        const assessmentId = searchParams.get('assessmentId');
        if (student.status === 'Submitted') {
            navigate(`/assessments/analysis?student=${student.id}&assessment=${assessmentId}`)
        } else {
            navigate(`/assessments/submittion?student=${student.id}&assessment=${assessmentId}`)
        }


    }


    return (
        <div className="assessment_list">
            <div style={{ width: '95%', padding: 25 }}>
                <PageHeader  title="Assessment / Students" />

                <div className="assessment_list__content">

                    <StudentListTable
                        onStudentClick={onStudentClick}
                        data={students} />
                </div>
            </div>
        </div>
    )
}

export default StudentListScreen;