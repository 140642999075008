import { useAxios } from "../useAxios";
import { ApiCollection } from "../../config/envConfig";
import { IOrganisation } from "../../utils/interface/organisation";
import { useDispatch } from "react-redux";
import {
  setOrganisations,
  setLoading,
  setError,
} from "../../redux/organisationSlice";

interface IOrganisationResponse {
  status: boolean;
  organisation: IOrganisation[];
}

export const useOrg = () => {
  const axios = useAxios();
  const dispatch = useDispatch();

  const getOrganisationInfo = async (): Promise<IOrganisation[] | null> => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(
        ApiCollection.organisation.getOrganisationDetail
      );
      const data: IOrganisationResponse = response.data;

      if (!data || !data.organisation) {
        throw new Error("No organisation data found in response");
      }

      const organisations = data.organisation.map((org) => ({
        organisation_id: org?.organisation_id || "",
        name: org?.name || "",
        address: org?.address || "",
        contact_number: org?.contact_number || null,
        org_logo: org?.org_logo || null,
        students: (org?.students || []).map((student) => ({
          student_id: student?.student_id || "",
          name: student?.name || "",
          role: student?.role || "",
          is_admin: student?.is_admin || false,
        })),
        assessment_papers: (org?.assessment_papers || []).map((paper) => ({
          assessment_paper_id: paper?.assessment_paper_id || "",
          name: paper?.name || "",
          assessment_class: paper?.assessment_class || "",
          set: paper?.set || null,
          total_marks: paper?.total_marks || 0,
          created_at: paper?.created_at || "",
        })),
      }));

      dispatch(setOrganisations(organisations));
      return organisations;
    } catch (error: any) {
      console.error("Error fetching organisation info:", error);
      dispatch(setError(error.message));
      return null;
    } finally {
      dispatch(setLoading(false));
    }
  };

  return { getOrganisationInfo };
};
