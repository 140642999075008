import React from "react";
import './styles.css'

const ReccommendationTable = (props:any) => {


    const data = props.data
  
  
    return (
      <div className="table-container">
        <table className="recommendation-table">
          <thead>
            <tr>
              <th className="col-name">Recommendation</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row:any, index:any) => (
              <tr key={index}>
                <td className="col-name">{row.topic}</td>
                <td>{row.details}</td>
              </tr>
            ))}
          </tbody>
        </table>
       
      </div>
    );
  };
  
  export default ReccommendationTable;