import { useSelector } from "react-redux";
import { selectUser, selectIsLoggedIn } from "./userSlice";
import {
  selectOrganisations,
  selectOrgLoading,
  selectOrgError,
  selectStudentsByOrgId,
} from "./organisationSlice";
import { LocalUser } from "../utils/interface/localUser";
import { RootState } from "./store";
import { IOrganisation } from "../utils/interface/organisation";

export function useUser(): LocalUser | null {
  return useSelector((state: RootState) => selectUser(state));
}

export function useIsLoggedIn(): boolean {
  return useSelector((state: RootState) => selectIsLoggedIn(state));
}

export function useOrganisations(): IOrganisation[] {
  return useSelector((state: RootState) => selectOrganisations(state));
}

export function useOrgLoading(): boolean {
  return useSelector((state: RootState) => selectOrgLoading(state));
}

export function useOrgError(): string | null {
  return useSelector((state: RootState) => selectOrgError(state));
}

export function useStudentsByOrgId(orgId: string): any[] {
  return useSelector((state: RootState) => selectStudentsByOrgId(state, orgId));
}
