import React from 'react';
import './styles.css';


const SpinnerLoader = () => {
  return (
    <div className="loader-container">
    <div className="spinner"></div>
  </div>

  );
};

export default SpinnerLoader;