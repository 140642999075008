import React from "react";
import './styles.css'

const PerformanceTable = (props:any) => {


    const data = props.data.filter((row:any,index:any) => index < 4);
  
    const CircularProgress = ({ percentage, swotType }:any) => {
      const circumference = 2 * Math.PI * 20;
      const strokeDashoffset = circumference - (percentage / 100) * circumference;
      const progressClass = `circular-progress ${swotType.toLowerCase()}`;
  
      return (
        <div className="progress-wrapper">
          <svg className="circular-progress-svg">
            <circle
              cx="24"
              cy="24"
              r="20"
              className="progress-background"
            />
            <circle
              cx="24"
              cy="24"
              r="20"
              className={progressClass}
              style={{ 
                strokeDasharray: circumference,
                strokeDashoffset: strokeDashoffset 
              }}
            />
          </svg>
          <span className={"progress-text "+progressClass}>{percentage}</span>
        </div>
      );
    };
  
    return (
      <div className="table-container">
        <table className="performance-table">
          <thead>
            <tr>
              <th className="col-name">Topic Name</th>
              <th>Achieved Marks</th>
              <th>Total Marks</th>
              <th>SWOT</th>
              <th>Attempt</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row:any, index:any) => (
              <tr key={index}>
                <td className="col-name">{row.topic}</td>
                <td>{row.achieved}</td>
                <td>{row.total}</td>
                <td>
                  <span className={`swot-badge ${row.swot.toLowerCase()}`}>
                    {row.swot}
                  </span>
                </td>
                <td>
                  <CircularProgress percentage={row.attempt} swotType={row.swot} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
       
      </div>
    );
  };
  
  export default PerformanceTable;