import React from "react";
import { AltArrowRight } from "solar-icon-set/arrows";
import './styles.css';

interface Student {
    id: number;
    name: string;
    roll: number;
    profileImage: string;
    // createdOn: string;
    status: string;
    // submitted: number;
}

interface StudentListTableProps {
    data: Student[];
    onStudentClick?: (student: Student) => void;
}

export default function StudentListTable(props: StudentListTableProps) {
    return (
        <table className="student_table">
            <thead>
                <tr>
                    <th>Student Name</th>
                    <th className="extras">Roll Number</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((student) => (
                    <tr className="student_table_data" key={student.id} onClick={() => props.onStudentClick && props.onStudentClick(student)}>
                        <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={student.profileImage} alt="student" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                                {student.name}
                            </div>
                        </td>
                        <td className="extras">{student.roll}</td>
                        <td>
                            <span className={`status_badge ${student.status === 'Submitted' ? 'status_active' : 'status_inactive'}`}>
                                {student.status}
                            </span>
                        </td>
                        <td >
                            <AltArrowRight size={20} iconStyle="Outline" />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}